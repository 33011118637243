<script>
import { SuawMainContent, SuawParagraph, SuawHeading, SuawBlockQuote } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "AttendingTheMuseOrYourWritingDiscipline",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    SuawBlockQuote,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="AttendingTheMuseOrYourWritingDiscipline">
      <SuawParagraph
        text="<em>Our founder, Rennie Saunders, explains where our tagline Attending the Muse came from and why it’s an important ritual in developing your writing discipline.</em>"
      />
      <SuawParagraph
        text="Your Muse is the outward personification of your most creative aspirations – emotional poetry or rocking song, epic play or lovely sonnet, exciting novel or mind-bending short story, mathematically challenging cantata or romantic symphony, tragic history or insightful essay. All of this and more are inside of your mind, but you cannot just sit down and say to the pen, give me an award winning story RIGHT NOW!"
      />
      <SuawParagraph
        text="You have to pay attention to your words, develop your craft, write every day without editing, edit every week without taking it personally, and keep a journal of all the ideas your muse will make available to you because she is happy with your attention."
      />
      <SuawParagraph
        text="How do you get to the point in your craft where the ideas spill forth and you are excited to sit down and write, where neither Hell nor high water can keep you from the page, and the wellspring of ideas flows from your pen?"
      />
      <SuawParagraph
        text="Let me offer an answer; you have the idea for a novel or literary short story or screenplay tumbling around in the creative recesses of your mind. In order to get that idea out of your head and onto the page you need a method. We all have different strategies for how we get around to the act of writing, some more successful than others, but the one every successful writer uses is “butt in chair.” But there is something else needed to change the process of writing from something you do when you are in the mood, to something you do because it has become a siren song, calling you to to the page."
      />
      <SuawParagraph
        text="In order to finish your story and work it into a shape you feel good about sharing, you must show up on a regular basis, week after week. Before you realize it, you’ve developed discipline."
      />
      <SuawHeading level="3" content="Writing discipline is what happens when you replace mood with persistence." />
      <SuawParagraph
        text="It’s what happens when you force yourself to show up at the page despite every excuse you can come up with, when your writing improves through practice and suddenly you have more ideas to share than years in your lifetime. It means you’ve developed a working relationship with that most fickle of mistresses, your creativity."
      />
      <SuawParagraph
        text="You have set yourself up for success in your writing by attending the Muse. Discipline, attention and persistence are how you win the love of another, and in this case the love you are seeking is your creativity."
      />
      <SuawParagraph
        text="The best part is, although this can be overwhelming, <strong>all you have to do is show up on a consistent basis.</strong> Just as a journey of a thousand miles begins with a single step, a novel of a thousand pages begins with a single paragraph:"
      />
      <SuawBlockQuote
        text="O Divine Poesy, goddess, daughter of Zeus, sustain for me this song of the various-minded man who, after he had plundered the innermost citadel of hallowed Troy, was made to stay grievously about the coasts of men, the sport of their customs, good and bad, while his heart, through all the sea-faring, ached with an agony to redeem himself and bring his company safe home. Vain hope – for them. The fools! Their own witlessness cast them aside. To destroy for meat the oxen of the most exalted Sun, wherefore the Sun-god blotted out the day of their return. Make this tale live for us in all its many bearings, O Muse.<br/>~ Homer’s Odyssey, translation by T.E. Lawrence"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
